@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Dosis;
  font-weight: 100;
  src: url("./assets/fonts/Dosis-ExtraLight.ttf");
}

@font-face {
  font-family: Dosis;
  font-weight: 200;
  src: url("./assets/fonts/Dosis-Light.ttf");
}

@font-face {
  font-family: Dosis;
  font-weight: 400;
  src: url("./assets/fonts/Dosis-Medium.ttf");
}

@font-face {
  font-family: Dosis;
  font-weight: 500;
  src: url("./assets/fonts/Dosis-Regular.ttf");
}

@font-face {
  font-family: Dosis;
  font-weight: 600;
  src: url("./assets/fonts/Dosis-SemiBold.ttf");
}

@font-face {
  font-family: Dosis;
  font-weight: 700;
  src: url("./assets/fonts/Dosis-Bold.ttf");
}

@font-face {
  font-family: Dosis;
  font-weight: 800;
  src: url("./assets/fonts/Dosis-ExtraBold.ttf");
}

* {
  font-family: Dosis;
}
